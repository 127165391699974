import React, { useEffect, useState } from "react"

import { Typography } from "@mui/material"
import { useMutationFinalize } from "api/reactQuery/mutations/teacher"
import { useQueryGetSchools } from "api/reactQuery/queries/getSchools"
import RegisterButtons from "components/common/button/registerButtons"
import Dialog from "components/common/dialog/dialog"
import { useDialog } from "hooks/dialog"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import {
  personalForm,
  selectDataAuth,
  selectOwnSchool,
  selectRegistrationData,
} from "store/auth/auth.selectors"
import {
  setActivePageSignUp,
  setAuthData,
  setOwnSchool,
  setPersonalForm,
  setTeacherRegistrationData,
} from "store/auth/auth.slice"
import { IAutocompleteProps } from "ts/interfaces/Input"
import { getErrorMessageFromTab } from "utils/api"

import AutocompleteInput from "./autocompleteInput/AutocompleteInput"

const FindSchoolForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { showSnackbar } = useCustomSnackbar()
  const userForm = useAppSelector(personalForm)
  const dataAuth = useAppSelector(selectDataAuth)
  const ownSchool = useAppSelector(selectOwnSchool)
  const teacherRegistrationData = useAppSelector(selectRegistrationData)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [school, setSchool] = useState<number | null>(null)
  const [state, setState] = useState<IAutocompleteProps | null>(null)
  const [errorInfo, setErrorInfo] = useState("")
  const [userPost, setUserPost] = useState<string | undefined>(
    teacherRegistrationData === undefined
      ? ""
      : teacherRegistrationData.school.name
  )
  const [displayInfo] = useState(teacherRegistrationData)
  const [skip, setSkip] = useState(0)

  const { isOpen, handleOpen, handleClose } = useDialog()

  const handleClickFb = () => {
    if (window.fbq) {
      window.fbq("track", "CompleteRegistration")
    }
  }

  useEffect(() => {
    if (typeof school === "number") {
      dispatch(setPersonalForm({ ...userForm, school_id: state?.id }))
    }
  }, [school])

  useEffect(() => {
    if (state?.name.length !== 0) {
      setErrorInfo("")
    }
  }, [state])

  useEffect(() => {
    if (ownSchool !== null) {
      dispatch(setPersonalForm({ ...userForm, school_id: ownSchool?.id }))
      setState(ownSchool)
    }

    return () => {
      dispatch(setOwnSchool(null))
    }
  }, [])

  const { mutate: finalize } = useMutationFinalize({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("userMe")
        showSnackbar({
          title: t("noAuthFlow.loginSuccessful"),
        })
        dispatch(
          setAuthData({
            ...dataAuth,
            finalized: true,
          })
        )
        navigate(RoutePath.HOMEPAGE)
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (state === null) {
      setErrorInfo(t("validations.fieldIsRequired"))
      return
    }
    if (displayInfo !== undefined && displayInfo?.school.name !== state.name) {
      handleOpen()
      return
    }
    finalize(userForm)
    handleClickFb()
  }

  const {
    data: schools,
    isLoading: isLoadingSchool,
    isSuccess,
    refetch,
  } = useQueryGetSchools({
    params: { filter: userPost, limit: 20, skip: skip },
    options: {
      enabled: !!userPost,
    },
  })

  useEffect(() => {
    if (teacherRegistrationData?.school.name && isSuccess) {
      dispatch(
        setPersonalForm({
          ...userForm,
          school_id: schools?.data.objects[0].id,
        })
      )
      setState((schools?.data.objects[0] as IAutocompleteProps) || null)
    }

    return () => {
      dispatch(setTeacherRegistrationData(undefined))
    }
  }, [isSuccess])

  return (
    <>
      <form onSubmit={onSubmit}>
        <Typography variant="h2" color="mockup.neutral50" textAlign="left">
          {t("noAuthFlow.findYourSchool")}
        </Typography>
        <AutocompleteInput
          userPost={userPost}
          isLoading={isLoadingSchool}
          setUserPost={setUserPost}
          data={schools?.data.objects || []}
          errorInfo={errorInfo}
          setState={setState}
          setSchool={setSchool}
          state={state}
          skip={skip}
          setSkip={setSkip}
          onClickNextSkip={() => {
            setSkip((prev) => prev + 20)
            setTimeout(() => {
              refetch()
            }, 0)
          }}
          onClickPrevSkip={() => {
            setSkip((prev) => prev - 20)
            setTimeout(() => {
              refetch()
            }, 0)
          }}
        />
        <RegisterButtons
          submitButton={t("noAuthFlow.done")}
          onClick={() => dispatch(setActivePageSignUp(2))}
        />
        <Dialog
          open={isOpen}
          onClose={handleClose}
          onActionButtonClick={() => {
            finalize(userForm)
          }}
          actionAcceptText={t("noAuthFlow.accept")}
          titleText={t("noAuthFlow.warning", {
            schoolName: displayInfo?.school.name,
          })}
        >
          <Typography variant="body2" color="mockup.neutral10">
            {t(
              "noAuthFlow.youHaveChosenDifferentSchoolIfYouSelectYourCurrentSchoolYouWillNotBeAbleToAcceptheInvitationFrom",
              {
                schoolName: displayInfo?.school.name,
              }
            )}
          </Typography>
        </Dialog>
      </form>
    </>
  )
}

export default FindSchoolForm
