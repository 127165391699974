import { Box } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { selectUser } from "store/auth/auth.selectors"

import Listing from "./listing"
import Table from "./table"

const StoreUpdates = () => {
  const userInfo = useAppSelector(selectUser)
  const isTablet = useIsBreakpointUp("tablet")

  return (
    <Box
      width="100%"
      maxHeight="400px"
      sx={{ overflowY: "auto" }}
      className="smooth-scrollbar"
    >
      {isTablet ? (
        <Table userInfo={userInfo} />
      ) : (
        <Listing userInfo={userInfo} />
      )}
    </Box>
  )
}

export default StoreUpdates
